:global(body) {
  overflow-x: hidden;
}

.parent {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.leftBar {
  composes: bg-dark p-2 from global;
  width: 230px;
  z-index: 100;
}

.brand {
  align-items: center;
}

.brandTitle {
  composes: text-white font-weight-bold from global;
  display: block;
  font-size: 30px;
}

.main {
  flex-grow: 1;
  display: flex;
  padding-top: 56px;
  position: relative;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content:not(:global(.bg-light)) > * :global(.search) > * input {
  background-color: var(--bs-white) !important;
}

@media screen and (max-width: 992px) {
  .leftBar {
    width: 82px;
    justify-content: center;
  }
  .brandTitle {
    display: none;
  }
  .brand {
    justify-content: center;
  }
  .content {
    flex-grow: 1;
    max-width: calc(100vw - 82px);
  }
}
