.fileInputImg {
  composes: rounded-circle fit-cover from global;
  height: 150px;
  width: 150px;
}
.fileInputText {
  composes: rounded-circle d-flex align-items-center justify-content-center position-absolute text-center from global;
  background-color: rgba(0, 0, 0);
  opacity: 0;
  transition: opacity 0.1s linear;
  height: 150px;
  width: 150px;
  cursor: pointer;
  top: 0;
}

.fileInputText:hover {
  opacity: 0.6;
}

.dateTimeInput {
}

.dateTimeInput select {
  pointer-events: all;
  padding-top: calc(0.75rem + 0.75rem * (2 / 3));
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(0.75rem / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  padding-right: calc(100% - 60px);
}

.dateTimeInput label {
  pointer-events: none;
  padding-top: calc(0.75rem / 3);
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(0.75rem / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

.dateTimeInputOpen {
  composes: form-control from global;
  composes: dateTimeInput;
  padding-bottom: 19px;
}

.dateTimeInputOpen select {
  position: relative;
  top: 19px;
}
