// Reference of all possible variables here :
// https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss

/* Logos TODO: sign logo(ok), refacto others then remove this block*/
$main-menu-logo-sm: url('../images/syment-logo-yellow.svg');
$main-menu-logo-lg: url('../images/syment-logotype-yellow-white.svg');
$sign-logo: url('../images/syment-logotype-yellow-white.svg');

// ------------------------------------------------------

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Responsive font sizes
// https://getbootstrap.com/docs/4.3/content/typography/#responsive-font-sizes
$enable-responsive-font-sizes: true;
// $font-family-sans-serif: 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans',
//   sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
//   'Noto Color Emoji' !default;
// Color system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$body-color: #36383a;

$blue: #2c8add !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #ff2c00 !default;
$orange: #ff9000 !default;
$yellow: #ffcc00 !default;
$green: #2bc952 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

// scss-docs-start colors-map
$colors: (
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'white': $white,
  'gray': $gray-600,
  'gray-dark': $gray-800,
) !default;
// scss-docs-end colors-map

$primary: $yellow !default;
$secondary: $gray-900 !default;
$success: $green !default;
$info: $blue !default;
$warning: $orange !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $black !default;

$primary-rgb: 255, 204, 0;
$secondary-rgb: 5, 113, 208;
$success-rgb: 117, 217, 142;
$danger-rgb: 255, 44, 0;
$warning-rgb: 255, 144, 0;
$info-rgb: 44, 138, 221;
$light-rgb: 233, 236, 239;
$dark-rgb: 12, 9, 13;

/* plaints status colors */
$plaint-new-color: #ff0032;
$plaint-waiting-color: #ff9000;
$plaint-working-color: #2c8add;
$plaint-resolved-color: #75d98e;

/* Polls colors */
$dark-purple: #2f1737;
$chart-red: #ff284d;

/* Assembly */
$assembly-red: #ff2c00;
$assembly-blue: #0dabea;
$vote-pour: $success;
$vote-contre: #ff5f3e;
$vote-abstention: $gray-400;
$vote-none: $gray-400;
$vote-other: $primary;

// scss-docs-start theme-colors-map
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'plaint-new': $plaint-new-color,
  'plaint-waiting': $plaint-waiting-color,
  'plaint-working': $plaint-working-color,
  'plaint-resolved': $plaint-resolved-color,
  'vote-pour': $vote-pour,
  'vote-contre': $vote-contre,
  'vote-abstention': $vote-abstention,
  'vote-none': $vote-none,
  'vote-other': $vote-other,
  'gray': $gray-600,
) !default;
// scss-docs-end theme-colors-map

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black !default;
$color-contrast-light: $white !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 1.5rem !default;

// Container padding

$container-padding-x: $grid-gutter-width * 2.5 !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-radius: 0.375rem !default;
$border-radius-sm: 0.3rem !default;
$border-radius-lg: 0.45rem !default;

// Links
//
// Style anchor elements.

$link-color: $secondary !default;

// Breadcrumbs

$breadcrumb-font-size: null !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-item-padding-x: 0.5rem !default;
$breadcrumb-margin-bottom: 1rem !default;
$breadcrumb-bg: transparent !default;
$breadcrumb-divider-color: $gray-600 !default;
$breadcrumb-active-color: $gray-600 !default;
$breadcrumb-divider: quote('/') !default;
$breadcrumb-border-radius: $border-radius !default;

// Badges

$badge-font-size: 0.75em !default;
$badge-color: $white !default;
$badge-padding-y: 0.35em !default;
$badge-padding-x: 0.65em !default;
$badge-border-radius: $border-radius !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-font-weight: 500 !default;
$btn-disabled-opacity: 0.35 !default;
$btn-white-space: nowrap;

// Typography

// $font-size-base: .875rem !default;

// Navs

$nav-pills-link-active-color: $primary;
$nav-tabs-link-active-bg: $gray-900;
$nav-link-transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, fill 0.15s ease-in-out,
  border-color 0.15s ease-in-out !default;

// Forms

$input-focus-border-color: $gray-700 !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-margin-bottom: 1rem !default;
$alert-border-radius: 4px !default;

$alert-bg-level: 0 !default;
$alert-border-level: 0 !default;
$alert-color-level: -12 !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding-y: 1rem !default;
$table-cell-padding-x: 1rem !default;
$table-hover-bg-factor: 0.025 !default;

// Modals

// Padding applied to the modal body
// $modal-inner-padding: 2rem !default;

// Progress bars

$progress-height: 0.6rem !default;
$progress-border-radius: 5rem !default;

// tooltip
$tooltip-max-width: 400px !default;

$utilities: (
  'opacity': (
    property: opacity,
    values: (
      0: 0,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      100: 1,
    ),
  ),
  'left': (
    property: left,
    values: (
      0: 0,
      50: 0.5,
      100: 1,
    ),
  ),
  'right': (
    property: right,
    values: (
      0: 0,
      50: 0.5,
      100: 1,
    ),
  ),
  'font-size': (
    property: font-size,
    class: font,
    values: (
      xs: 0.8rem,
    ),
  ),
);
