// All SCSS Override Bootstrap
// Following : https://github.com/twbs/bootstrap/tree/main/scss

.btn {
  --bs-btn-active-border-color: transparent;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: transparent;
  --bs-btn-hover-bg: transparent;
}

.btn.btn-success {
  background: #2bc952;
}
.btn.btn-success:hover {
  background-color: #4bd16c;
}

.btn.btn-primary {
  color: #000;
  background-color: #fc0;
  border-color: #fc0;
}
.btn.btn-primary:hover {
  color: #000;
  background-color: #ffd426;
  border-color: #ffd11a;
}

.btn.btn-danger {
  background-color: #ff2c00;
  border-color: #ff2c00;
}

.btn.btn-danger:hover {
  background-color: #ff4c26;
  border-color: #ff411a;
}

.btn.btn-secondary {
  background-color: #212529;
  border-color: #212529;
  color: #fc0;
}

.btn.btn-secondary:hover {
  background-color: #1c1f23;
  border-color: #1a1e21;
  color: #fc0;
}

// voting button
.btn-check:checked + .btn {
  background-color: var(--bs-primary);
}

// Navs
#mainMenu {
  .nav-pills {
    .nav-link {
      color: $gray-400;
      fill: $gray-400;

      &:hover {
        color: $white;
        fill: $white;
      }
    }

    .nav-link.active,
    .show > .nav-link {
      color: $nav-pills-link-active-color;
      fill: $nav-pills-link-active-color;
      background: $nav-tabs-link-active-bg;
    }
  }
}

.nav-pills {
  &-horizontal {
    border-bottom: 1px solid $gray-300;

    .nav-item {
      position: relative;
    }

    .nav-link {
      color: $gray-500;
      border-bottom: 2px solid transparent;
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
      margin-right: 2rem;

      &:hover {
        color: $dark;
      }
    }
    .nav-link:last-child {
      margin-right: 0;
    }

    .nav-item:last-child .nav-link {
      margin-right: 1rem;
    }

    .nav-link.active,
    .show > .nav-link {
      color: $dark;
      color: $dark !important;
      background-color: transparent;
      border-bottom: 2px solid $primary;
    }
  }
}

.navbar {
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(1rem);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.05);

  .active {
    border-bottom: 2px solid $primary;

    svg {
      fill: $dark;
    }
  }

  svg {
    height: 22px;
    width: auto;
    fill: $gray-700;
  }
}

/* Fix BS-5 bug maybe ? */
.modal-dialog-scrollable .modal-content {
  max-height: 85vh;
}

.modal-dialog-scrollable .modal-content.activation-users-modal {
  max-height: 100vh;
}

.btn {
  &-danger {
    color: $white;
  }

  &-secondary {
    color: $primary;

    &:hover {
      color: $primary;
    }

    &:focus {
      color: $primary;
    }
  }

  &-black {
    color: $primary;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity));

    &:hover {
      color: $primary;
    }

    &:focus {
      color: $primary;
    }
  }

  &-light {
    background-color: $gray-300;
    border-color: $gray-300;

    &:hover {
      background-color: $gray-400;
      border-color: $gray-400;
    }

    &:disabled,
    &.disabled {
      background-color: $gray-300;
      border-color: $gray-300;
    }
  }
}

.badge.bg-light {
  background-color: $gray-300 !important;
}

/*---------------
BREADCRUMB
--------------*/

.breadcrumb-item {
  a {
    text-decoration: none;
    color: $gray-600;

    &:hover {
      color: $gray-800;
    }
  }
}

/*---------------
BADGES
--------------*/

.badge {
  margin-top: -3px;
  vertical-align: middle;
}

.badge-primary {
  background-color: rgba($primary, 0.2);
  color: var(--bs-primary);
}

.badge-danger {
  background-color: rgba($danger, 0.2);
  color: var(--bs-danger);
}

.badge-secondary {
  background-color: rgba($secondary, 0.2);
  color: var(--bs-secondary);
}

.badge-info {
  background-color: rgba($info, 0.2);
  color: var(--bs-info);
}

.badge-success {
  background-color: rgba($success, 0.2);
  color: var(--bs-success);
}

/*---------------
react-select
--------------*/
.react-select {
  &-container {
    margin-bottom: 1rem !important;
  }
  &__control {
    height: 50px !important;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    &--is-focused,
    &--menu-is-open {
      border-color: $primary !important;
      box-shadow: 0 0 0 1px rgba($primary, 0.2) !important;
    }
  }

  &__value-container {
    top: 15% !important;
  }
  &__indicators {
  }
  &__menu {
  }
  &__menu-list {
  }
  &__option {
    &--is-selected {
    }
  }
  &__placeholder {
  }
}

div.react-select-container.react-select--is-disabled
  > .react-select__control--is-disabled {
  background-color: #e9ecef;
}

div.react-select__single-value.react-select__single-value--is-disabled {
  color: #36383a;
}

/*---------------
 MODAL
---------------*/
.modal {
  z-index: 99999;
  &.simplemodal {
    display: flex;
    .modal-dialog {
      display: flex;
      align-items: center;
    }
  }
}

.modal-fullscreen {
  width: 85vw;
  height: 95vh;
  left: 8%;
  top: 3.5vh;
}
.modal-fullscreen > .modal-content {
  border-radius: $border-radius;
}

.modal-header,
.modal-body {
  padding: 1rem 2rem 1rem 2rem;
}

/*--------------
 ALERT
----------------*/
.alert-danger {
  background-color: $red-400;
  color: $white;
  border-color: $red;
}

.btn-light.btnActive {
  background-color: #ced4da;
}

.btn-light.btnActive:active {
  color: white;
}

/*---------------
PROGRESS BAR
--------------*/
.progress-bar {
  border-radius: 5rem;
}
