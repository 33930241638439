#root {
  display: flex;
  min-height: 100vh;
}

/* RANGE INPUT @see http://danielstern.ca/range.css/ */
input[type='range'].range {
  -webkit-appearance: none;
  width: 100%;
  margin: 6.5px 0;
}
input[type='range'].range:focus {
  outline: none;
}
input[type='range'].range::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  background: #b9b9b9;
  border-radius: 5px;
  border: 0px solid #ffffff;
}
input[type='range'].range::-webkit-slider-thumb {
  box-shadow: 0px 0px 0.1px #ffcc00, 0px 0px 0px #ffd11a;
  border: 0px solid #ffcc00;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background: #ffcc00;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6.5px;
}
input[type='range'].range:focus::-webkit-slider-runnable-track {
  background: #b9b9b9;
}
input[type='range'].range::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  background: #b9b9b9;
  border-radius: 5px;
  border: 0px solid #ffffff;
}
input[type='range'].range::-moz-range-thumb {
  box-shadow: 0px 0px 0.1px #ffcc00, 0px 0px 0px #ffd11a;
  border: 0px solid #ffcc00;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background: #ffcc00;
  cursor: pointer;
}
input[type='range'].range::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range'].range::-ms-fill-lower {
  background: #b9b9b9;
  border: 0px solid #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
}
input[type='range'].range::-ms-fill-upper {
  background: #b9b9b9;
  border: 0px solid #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
}
input[type='range'].range::-ms-thumb {
  box-shadow: 0px 0px 0.1px #ffcc00, 0px 0px 0px #ffd11a;
  border: 0px solid #ffcc00;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background: #ffcc00;
  cursor: pointer;
  height: 5px;
}
input[type='range'].range:focus::-ms-fill-lower {
  background: #b9b9b9;
}
input[type='range'].range:focus::-ms-fill-upper {
  background: #b9b9b9;
}

/* Used to disabled link when disabled propos is passed to <Link> */
a[disabled] {
  pointer-events: none;
}

.notification-message {
  display: flex;
  align-items: center;
  white-space: normal;
}

#notifications-topRight {
  /* Make it positionnable by option*/
  position: fixed;
  top: 50px;
  right: 50px;
  z-index: 999999;
}

#notifications-bottomLeft {
  /* Make it positionnable by option*/
  position: fixed;
  bottom: 50px;
  left: 50px;
  z-index: 999999;
}
.cursor-pointer {
  cursor: pointer;
}

.cke_contents {
  height: 55vh !important;
}

.ghostClass {
  opacity: 1;
  background-color: rgba(255, 204, 2, 0.2);
}
.ghostClass .card {
  opacity: 0;
}

.dragClass {
  opacity: 0.5;
}
