@tailwind base;
@tailwind components;
@tailwind utilities;

@import './variables.scss';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/utilities/api';

@import '~bootstrap/scss/bootstrap';
@import './palette.scss';

// Include then all overided style
@import './overrides.scss';

/*---------------
GENERAL
--------------*/

:root {
  --input-padding-x: 0.75rem;
  --input-padding-y: 0.75rem;
  --plaint-new-rgb: 255, 0, 50;
  --plaint-waiting-rgb: 255, 144, 0;
  --plaint-working-rgb: 44, 138, 221;
  --plaint-resolved-rgb: 117, 217, 142;
  --chart-red-rgb: 255, 40, 77;
  --dark-rgb: 12, 9, 13;
  --light-rgb: #{$light-rgb};
  --bs-margin-top-2: #{map-get($spacers, 2)};
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
}

/* Removing input background colour for Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
}

.fit-cover {
  object-fit: cover;
}

*:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

/*-------------
ICONS stuff
--------------- */
svg.disabled {
  color: $gray-300;
}
/*---------------
FOR OLD BROWSERS IE
--------------*/

/* IE10+ CSS styles go here */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sticky-top {
    position: relative;
    z-index: 1020;
  }
}

/* IE 11 (and above..) */

_:-ms-fullscreen,
:root .sticky-top {
  position: relative;
  z-index: 1020;
}

/* IE Edge 12+ CSS styles go here */

@supports (-ms-accelerator: true) {
  .sticky-top {
    position: relative;
    z-index: 1020;
  }
}

.bg-gray-100 {
  background-color: $gray-100;
}

.bg-gray-500 {
  background-color: $gray-500;
}

/**
 * ===================================================================
 * preloader
 *
 * -------------------------------------------------------------------
 */

#preloader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 1100;
  height: 100%;
  width: 100%;
}

.no-js #preloader,
.oldie #preloader {
  display: none;
}

.t-center {
  text-align: center;
}

.t-right {
  text-align: right;
}

/**
 * ===================================================================
 * NEWSFEED
 *
 * -------------------------------------------------------------------
 */

.new-publication {
  &:hover {
    box-shadow: 0 0 10px 0 rgba(157, 166, 177, 0.2);
  }

  &-textarea {
    border: none;
    overflow: auto;
    outline: none;
    box-shadow: none;
    resize: none; /*remove the resize handle on the bottom right*/

    &:focus {
      box-shadow: none;
    }
  }
}

/*---------------
TABLE
--------------*/

table.custom-table {
  border-spacing: 0 6px;
  border-collapse: separate;

  > thead {
    tr {
      border: none;
    }

    th {
      font-size: 0.845rem;
      color: #9da6b1;
      border: none;
      padding-top: 0.2rem !important;
      padding-bottom: 0.2rem !important;
      text-transform: uppercase;

      &:hover {
        background-color: #fafafa;
      }
    }
  }

  > tbody {
    tr {
      border: 1px solid rgb(240, 242, 245);
    }

    td {
      font-size: 0.9rem;
      vertical-align: middle;
      background-color: white;
      border-top: 1px rgb(240, 242, 245) solid;
      border-bottom: 1px rgb(240, 242, 245) solid;

      &:first-child {
        border-left: 1px rgb(240, 242, 245) solid;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-right: 1px rgb(240, 242, 245) solid;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
}

table {
  border-spacing: 0 6px;
  border-collapse: separate;

  > thead {
    tr {
      border: none;
    }

    th {
      font-size: 0.845rem;
      color: #9da6b1;
      border: none;
      padding-top: 0.2rem !important;
      padding-bottom: 0.2rem !important;
    }
  }

  > tbody {
    tr {
      border: 1px solid rgb(240, 242, 245);
    }

    td {
      font-size: 0.9rem;
      vertical-align: middle;
    }
  }

  > :not(caption) > * > * {
    padding: 0.5rem !important;
  }
}

.table-sorting {
  cursor: pointer;
  &:hover {
    color: $gray-600;
  }
}

.table-fixed {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  height: calc(100vh - (700px));
  thead {
    /* head takes the height it requires,
      and it's not scaled when table is resized */
    flex: 0 0 auto;
    width: calc(100% - 0.9em);
    z-index: 1;
  }
  tbody {
    /* body takes all the remaining available space */
    flex: 1 1 auto;
    display: block;
    overflow-y: scroll;
  }
  tbody tr {
    width: 100%;
  }
  thead,
  tbody tr {
    display: table;
    table-layout: fixed;
  }
}

/* Arrow right on dropdown menu */

.dropdown-menu {
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}
/* Align menu items to the left*/
.dropdown-menu.show {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.dropdown-menu-end {
    right: 0;

    span {
      white-space: none;
      padding: 0.5rem 1rem;
      cursor: pointer;
    }
  }
  .dropdown-item {
    width: 100%;
    .btn.w-100 {
      width: auto !important;
    }
  }
}

.dropdown-item.btn-visio {
  align-self: flex-start;
  font-size: 14px;
  font-weight: 700;
  color: $white;
  background: $green;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  &:hover {
    background-color: #4bd16c;
  }
}

ul.dropdown-menu.dropdown-menu-end.show {
  .dropdown-item.btn-visio {
    font-size: 11px;
    font-weight: 700;
    background-color: transparent;
    margin-left: 4px;
    color: #36383a;
  }
}

#userNavigation {
  &.scroll {
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  }

  .dropdown-menu-right {
    &::before {
      border-bottom: 9px solid rgba(0, 0, 0, 0.15);
      border-left: 9px solid rgba(0, 0, 0, 0);
      border-right: 9px solid rgba(0, 0, 0, 0);
      content: '';
      display: inline-block;
      right: 12px;
      position: absolute;
      top: -9px;
    }

    &::after {
      border-bottom: 8px solid #ffffff;
      border-left: 9px solid rgba(0, 0, 0, 0);
      border-right: 9px solid rgba(0, 0, 0, 0);
      content: '';
      display: inline-block;
      right: 12px;
      position: absolute;
      top: -7px;
    }
  }

  .dropdown-menu-left {
    &::before {
      border-bottom: 9px solid rgba(0, 0, 0, 0.15);
      border-left: 9px solid rgba(0, 0, 0, 0);
      border-right: 9px solid rgba(0, 0, 0, 0);
      content: '';
      display: inline-block;
      left: 12px;
      position: absolute;
      top: -9px;
    }

    &::after {
      border-bottom: 8px solid #ffffff;
      border-left: 9px solid rgba(0, 0, 0, 0);
      border-right: 9px solid rgba(0, 0, 0, 0);
      content: '';
      display: inline-block;
      left: 12px;
      position: absolute;
      top: -7px;
    }
  }

  /* Company header */
  // .company-header {
  //     position: absolute;
  //     top: 20px;
  // }
  /* Crypto warning in header (breadcrumb) */
  .crypto-warning {
    font-style: italic;
    color: #6d6c6c;
    font-size: 11px;
  }
}

/*---------------
NAVBAR
--------------*/

.nav-scroller {
  position: relative;
  z-index: 2;
  overflow-y: hidden;

  @media screen and (max-width: 576px) {
    margin-right: -15px !important;
  }

  .nav-pills {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
  }
}

.navbar-default .navbar-nav > li > a {
  color: #fff;
}

.navbar-nav > li > a {
  line-height: 40px;
  font-size: 20px;
}

.nav-user-icon {
  width: 30px;
  height: 30px;
  border-radius: 50px;
}

.nav-user-icon:hover {
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.2);
}

.nav-avatar img:hover {
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.2);
}

.navbar-nav > li > .dropdown-menu {
  border-radius: 4px;
}

.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: rgba(255, 255, 255, 0.6);
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  color: #fff;
  background-color: transparent;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: transparent;
  border-color: none;
}

.nav > li > a:focus,
.nav > li > a:hover {
  background-color: transparent;
}

/*---------------
FORM
--------------*/
textarea.form-control::placeholder {
  opacity: 0;
}

textarea.form-control {
  overflow: hidden;
}

.form-label-group {
  position: relative;
  margin-bottom: 0.5rem;

  > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all 0.1s ease-in-out;
    font-size: 14px;
  }

  > input,
  > textarea,
  > label {
    padding: 0.75rem 0.75rem;
    padding: var(--input-padding-y) var(--input-padding-x);
  }

  input::-webkit-input-placeholder {
    color: transparent;
  }

  input:-ms-input-placeholder {
    color: transparent;
  }

  input::-ms-input-placeholder {
    color: transparent;
  }

  input::-moz-placeholder {
    color: transparent;
  }

  input::placeholder {
    color: transparent;
  }

  input:not(:placeholder-shown),
  textarea:not(:placeholder-shown) {
    padding-top: calc(0.75rem + 0.75rem * (2 / 3));
    padding-top: calc(
      var(--input-padding-y) + var(--input-padding-y) * (2 / 3)
    );
    padding-bottom: calc(0.75rem / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
  }

  input:not(:placeholder-shown) ~ label,
  textarea:not(:placeholder-shown) ~ label {
    padding-top: calc(0.75rem / 3);
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(0.75rem / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777;
  }

  input:-webkit-autofill ~ label,
  textarea:-webkit-autofill ~ label {
    top: 0;
    left: 0;
    font-size: 12px;
  }
}

.form-select-group {
  position: relative;
  margin-bottom: 1rem;

  > select {
    padding-top: calc(0.75rem + 0.75rem * (2 / 3));
    padding-top: calc(
      var(--input-padding-y) + var(--input-padding-y) * (2 / 3)
    );
    padding-bottom: calc(0.75rem / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    height: 50px;
  }

  > label {
    font-size: 12px;
    color: #777;
    padding: 0.75rem 0.75rem;
    padding: var(--input-padding-y) var(--input-padding-x);
    padding-top: calc(0.75rem / 3);
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(0.75rem / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    position: absolute;
    top: 0;
  }

  > div.grouped {
    .react-select__group-heading {
      text-transform: none;
      font-size: 1rem;
      color: black;
    }
    .react-select__option {
      padding-left: 2rem;
    }
  }
}

/*---------------
CONTENT
----------------*/

.app-content {
  margin-left: 270px;
  margin-bottom: 4rem;
  padding-right: 30px;
  padding-left: 30px;

  @media screen and (max-width: 768px) {
    margin-left: 100px;
    padding-right: 15px;
    padding-left: 15px;
  }

  @media screen and (max-width: 576px) {
    margin-left: 50px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

/*---------------
CARD
--------------*/

div.card.selected {
  border: none;
  div.card-body {
    border: 1px solid #ffd014;
    border-radius: 6px;
  }
}

div.card.slim {
  div.card-body {
    padding-top: 5px;
    padding-bottom: 4px;
    h5.card-title {
      margin-bottom: 2px;
    }
  }
}
div.card.slim:hover {
  cursor: pointer;
}

.card-feature {
  min-height: 470px;
  max-height: 470px;
  border-width: 2px;
  overflow: hidden;
  // background: #F4F5F7;
  // border-color: #F4F5F7;

  .btn-all {
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 0.8px;
  }
}

.nav-scroller-x {
  position: relative;
  z-index: 2;
  overflow-y: hidden;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow-x: auto;

  @media screen and (max-width: 576px) {
    margin-right: -15px !important;
  }
}

.card-img-empty {
  height: 180px;
  margin-top: 2rem;
}

/*---------------
HEADER / USER NAVIGATION
--------------*/

.topbar {
  left: 270px;

  @media screen and (max-width: 768px) {
    left: 100px;
  }

  @media screen and (max-width: 576px) {
    left: 50px;
  }
}

.user-nav {
  ul {
    margin-bottom: 0;
  }

  ul > li > a {
    line-height: 40px;
    font-size: 20px;
    padding: 0.5rem;
  }
}

.selected-profile {
  &::before {
    content: '\f00c';
    font-family: 'Font Awesome 5 Free';
    display: inline-block;
    margin-left: 0;
    width: 1em;
  }
}

/*---------------
USER AVATAR
--------------*/

.user-avatar {
  height: 44px;
  width: 44px;
  object-fit: cover;
  border-radius: 50px;

  // responsive avatar
  &__fluid {
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;

    @media (min-width: 576px) {
      width: 34px;
      height: 34px;
      min-width: 34px;
      min-height: 34px;
    }

    @media (min-width: 768px) {
      width: 44px;
      height: 44px;
      min-width: 44px;
      min-height: 44px;
    }
  }

  &__sm {
    width: 26px;
    height: 26px;
  }

  &__md {
    width: 30px;
    height: 30px;
  }

  &__lg {
    width: 38px;
    height: 38px;

    @media (min-width: 576px) {
      width: 48px;
      height: 48px;
    }

    @media (min-width: 768px) {
      width: 68px;
      height: 68px;
    }
  }
}

/*---------------
COMPANY AVATAR
--------------*/

.company-avatar {
  width: 44px;
  height: 44px;
  object-fit: cover;
  border-radius: 4px;

  &__fluid {
    width: 28px;
    height: 28px;

    @media (min-width: 576px) {
      width: 34px;
      height: 34px;
    }

    @media (min-width: 768px) {
      width: 44px;
      height: 44px;
    }
  }

  &__sm {
    width: 26px;
    height: 26px;
  }

  &__md {
    width: 30px;
    height: 30px;
  }

  &__lg {
    width: 38px;
    height: 38px;

    @media (min-width: 576px) {
      width: 48px;
      height: 48px;
    }

    @media (min-width: 768px) {
      width: 68px;
      height: 68px;
    }
  }
}

/*---------------
SIDEBAR
--------------*/

#sidebar {
  width: 270px;
  min-width: 50px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 768px) {
    width: 100px;
  }

  @media screen and (max-width: 576px) {
    width: 50px;
  }
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  /* height: calc(86vh - 86px); */
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  .nav {
    li {
      a {
        color: white;
        font-size: 1.28rem;
        opacity: 0.6;

        img {
          height: 24px;

          @media screen and (min-width: 576px) {
            height: 32px;
          }

          @media screen and (min-width: 768px) {
            height: 24px;
          }
        }

        &:hover {
          opacity: 1;
        }

        &:focus {
          opacity: 1;
        }
      }
    }

    li.active {
      a {
        opacity: 1;
      }
    }
  }
}

.sidebar-brand {
  padding: 18px 7%;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding: 18px 0;
    margin: 0 auto;
    width: 30px;
  }
}

.sidebar-icon {
  vertical-align: text-bottom;
}

.company-settings {
  &_nav {
    color: rgba(255, 255, 255, 0.4);

    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .company-avatar {
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
}

/*---------------
FOOTER
--------------*/

footer {
  color: #555555;

  a {
    color: white;
    opacity: 0.6;
  }

  a:hover {
    color: white;
    opacity: 1;
  }

  .app-infos {
    button {
      background: none;
      border: none;
    }
  }
}

/*---------------
NOTIFICATIONS
--------------*/

.notification-chip {
  min-width: 22px;
  width: auto;
  height: 22px;
  background: var(--bs-danger);
  border: 2px solid white;
  margin: 16px 0 0 30px;
  top: -11px;
  left: -4px;
  padding: 0px 4px;
  position: absolute;
  text-align: center;
  line-height: 16px;
  font-size: 11px;
  color: #fff;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  z-index: 9;

  &__count {
    font-size: 10px;
    font-weight: 800;
  }
}

.notification-header {
  display: block;
  padding: 0rem 0.5rem 0.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.notification-body {
  width: 300px;
  max-height: 480px;
  overflow: hidden;
  overflow-y: auto;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.notification-body > a {
  font-size: 12px;
  font-weight: 400;
  white-space: normal;
  padding: 14px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.notification-body > a.readed {
  opacity: 0.5;
}

.notification-date {
  font-size: 10px;
  font-weight: 400;
  margin: 0;
  color: #aaa;
}

.no-notification {
  padding: 30px 20px;
  text-align: center;
  width: 300px;
}

.notification-message {
  display: block;
  /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 400px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 18px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  min-height: 36px;
}

// clamp for mozilla
@-moz-document url-prefix() {
  .notification-message {
    white-space: nowrap;
  }
}

.notification-footer {
  display: block;
  padding: 0.5rem 0.5rem 0rem;
  margin-bottom: 0;
  font-size: 0.74rem;
  white-space: nowrap;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

// Notification live

.ns-type-success {
  background-color: #fff;
  border-left: rgb(14, 163, 14) solid 8px;
  color: black;
}

.ns-type-error {
  background-color: #fff;
  border-left: rgb(192, 13, 13) solid 8px;
  color: black;
}

.ns-type-notice {
  background-color: #fff;
  border-left: rgb(13, 106, 192) solid 8px;
  color: black;
}

.ns-type-warning {
  background-color: #fff;
  border-left: rgb(219, 123, 12) solid 8px;
  color: black;
}

/*---------------
CONTENT
--------------*/

#content {
  flex: 1;
  order: 2;
}

/*---------------
RIGHT SIDEBAR (DISCUSSION / INBOX / NOTIFICATIONS)
--------------*/

.user-avatar-discussion-card {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5rem;
}

.backdrop {
  display: none;
  opacity: 0;
  bottom: 0px;
  left: 0px;
  pointer-events: initial;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 1050;
  background: rgba(12, 9, 13, 0.54);
  transition: all 1s ease-in-out;

  &__active {
    opacity: 1;
  }

  &__hidden {
    display: block;
  }
}

.right-sidebar {
  width: 300px;
  height: 100%;
  margin: 0px;
  position: fixed;
  top: 0px;
  right: -600px;
  background: #fff;
  z-index: 1051;
  transition: all 500ms ease-in-out;

  @media (min-width: 576px) {
    width: 500px;
  }

  @media (min-width: 768px) {
    width: 600px;
  }

  &__toggled {
    right: 0px;
  }
}

.not-read {
  width: 10px;
  height: 10px;
  background-color: $blue;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 $blue;
}

/*---------------
COMMENTS GENERAL
------------------*/

.comment {
  margin: 20px 0;

  &__header {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding: 0.75rem 0;
  }

  &__add {
    display: flex;
    margin: 20px 0;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;

    img {
      margin-right: 16px;
    }
  }

  &__add-message {
    flex: 1;

    textarea {
      resize: none;
      outline: none;
      max-height: 44px;
      min-height: 44px;
      padding: 0.5rem 0.75rem;
    }
  }

  &__add-button {
    margin-top: 12px;
  }

  &__avatar {
    float: left;
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50px;
    margin-right: 12px;
  }

  &__date {
    font-size: 11px;
    color: #999;
  }

  &__text {
    border-radius: 4px;
    font-size: 14px;
    margin: 3px;
    margin-left: 0px;
  }
}

/*---------------
FILES UPLOAD
------------------*/

.file-list {
  display: flex;

  &__item {
    height: 130px;
    width: 156px;
  }

  &__item-card {
    box-shadow: rgba(9, 30, 66, 0.2) 0px 1px 1px,
      rgba(9, 30, 66, 0.24) 0px 0px 1px 0px;
    cursor: pointer;
    line-height: normal;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background: rgb(255, 255, 255);
  }

  &__item-wrapper {
    display: block;
    height: inherit;
    position: relative;
    border-radius: 3px;
    background: rgb(244, 245, 247);
  }

  &__item-wrapper-img {
    position: relative;
    width: inherit;
    height: inherit;
    display: block;
    overflow: hidden;
    border-radius: 3px;
  }

  &__item-wrapper-img-back {
    width: 100%;
    height: 100%;
    background-size: cover, auto;
    background-repeat: no-repeat, repeat;
    background-position: center center, center center;
  }

  &__item-wrapper-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 3px;
    background: transparent;
    transition: background 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
      border-color 0.3s ease 0s;
    padding: 16px;
    color: #fff !important;

    &:hover {
      background-color: rgba(12, 9, 13, 0.5);
    }
  }
}

/*---------------
CGV
------------------*/

.cgv-content {
  background: white;
  padding: 2em;
}

/*-------------------
jqTree for documents
---------------------*/

.jqtree-closed > .jqtree-element i.icon-folder-open:before {
  content: '\f07b';
}

.jqtree-tree i {
  margin-right: 4px;
}

/*---------------------------
 sweetAlert2 (swal) override
---------------------------*/

.swal2-popup .swal2-styled.swal2-confirm {
  background-color: $primary !important;
  color: $dark !important;
}

.swal-button {
  &--confirm {
    color: #212529;
    background-color: $success;
    border-color: $success;

    &:not([disabled]):hover {
      background-color: darken($success, 5%);
      border-color: darken($success, 5%);
    }
  }
}

/*---------------------------
         Member list
----------------------------*/

.member-avatars {
  img {
    // -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.2);
    // box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.2);
    margin-right: -15px; // One avatar slightly overrides the previous one
  }
}

#membersList {
  tr.inactive {
    font-style: italic;
    color: lightgrey;

    a.text-secondary {
      color: lightgrey !important;
    }

    img {
      opacity: 0.2;
    }
  }
}

/*---------------
DROPZONE
------------------*/

.dropzone {
  position: relative;
  border: 1px dashed $gray-500;
  cursor: pointer;

  &-image {
    width: 156px;
    height: 125px;
    object-fit: cover;
  }
}

/*---------------
PLAINTS / REQUEST
------------------*/

.card-plaint {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: $gray-200;
  }
}

.btn-plaint-new {
  color: $white !important;
}

/*------------------
POLL VOTES
-------------------*/
.form-check-poll {
  margin-bottom: 0.8rem;

  .form-check-input {
    // hide the radio !
    position: absolute;
    visibility: hidden;
  }

  label {
    border: 2px solid $secondary;
    border-radius: 5rem;
    padding: 0.6rem 1rem;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
    transition: all 0.25s linear;
    font-weight: 700;
    min-height: 43px;
    font-size: 1.2rem;
  }

  input[type='radio']:checked ~ label {
    color: $primary;
    background: $secondary;
  }
}

.hover-bg-light:hover {
  background: $gray-200;
}

.tooltip {
  z-index: 100000;
}

.table > tbody tr .absent {
  color: $gray-500;
}

.pre-line {
  white-space: pre-line;
}

/* Accordion styles */
.tabs {
  overflow: hidden;
}
.tab {
  width: 100%;
  overflow: hidden;
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  &-label {
    display: flex;
    justify-content: left;
    padding: 1em;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
    &:hover {
      background: white;
    }
    &::after {
      content: '\276F';
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all 0.35s;
      margin-top: -3px;
      margin-left: 1rem;
    }
  }
  &-content {
    max-height: 0;
    padding: 0 1em;
    color: black;
    background: white;
    transition: all 0.35s;

    .description {
      white-space: pre-line;
    }
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    cursor: pointer;
  }
  .badge {
    margin-top: 0;
    height: 1.4rem;
  }
}

// :checked
input:checked {
  + .tab-label {
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 100vh;
    padding: 1em 3rem;
  }
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

@media (min-width: 576px) {
  .container-xxl.fullwidth,
  .container-xl.fullwidth,
  .container-lg.fullwidth,
  .container-md.fullwidth,
  .container-sm.fullwidth,
  .container.fullwidth {
    max-width: 100%;
  }
}

.form-check {
  display: flex;
  align-items: center;
  span.inverse {
    margin-right: 33px;
    margin-top: 2px;
  }
}

#dropdownMenuReference.dropdown-toggle::after {
  display: none;
}

.btn.btn-primary.dropdown-toggle.dropdown-toggle-split.downloadpv {
  transform: translateX(-3px);
}

.btn.btn-danger.dropdown-toggle.dropdown-toggle-split.downloadpv {
  transform: translateX(-3px);
}

.btn.btn-secondary.dropdown-toggle.dropdown-toggle-split.download-convoc {
  transform: translateX(-3px);
}

ul.downloadpv {
  right: 0 !important;
  left: auto !important;
  // height: 38px;
}

.btn-group > form > button.btn.btn-primary {
  padding: 0;
  .bdr-card {
    .card-body {
      padding: 0 1rem;
    }
  }
}

#cancel_votes {
  div {
    input {
      margin-bottom: 4px;
    }
  }
}

input[type='radio'].form-check-input.label-renderer {
  margin-right: 5px;
}

ul.list-unstyled li:not(:last-child) {
  margin-bottom: 1rem;
}
ul.list-unstyled.prepa_ag.odj li:not(:last-child) {
  margin-bottom: 3px !important;
}

ul.list-unstyled li:not(:last-child) {
  margin-bottom: 1rem;
}

.clickable {
  cursor: pointer;
}

section div.slim:not(:last-child) {
  margin-bottom: 3px;
}

#ag-listing {
  display: flex;
  justify-content: flex-end;
  margin-right: 22px;
}

#ag-listing ul.dropdown-menu.dropdown-menu-end.show {
  width: 285px;
}
#today-assemblies ul.dropdown-menu.dropdown-menu-end.show {
  width: 125px;
  display: flex;
  justify-content: space-between;
  & > a {
    padding-left: 8px;
    padding-right: 0;
    & > button > svg {
      margin-right: 12px;
    }
  }
}

#future-assemblies ul.dropdown-menu.dropdown-menu-end.show {
  display: flex;
  justify-content: space-between;
  & > a {
    padding-left: 8px;
    padding-right: 0;
    & > button > svg {
      margin-right: 12px;
    }
    & > button > div > svg {
      margin-right: 12px;
    }
  }
}

.modal-xl {
  max-width: 1400px;
}

div.modal-header.align-items-start.modal-center-title > h5 {
  width: 100%;
  text-align: center;
  font-size: 28px;
}

.vpc-radio-group {
  font-size: 14px;
  margin-bottom: 1px;
}

.form-check-input.vpc-radio-group-input {
  color: #9b9c9d;
}

label.form-check-label.vpc-radio-group.disabled,
label.form-check-label.edit-assembly-visio.disabled {
  color: #9b9c9d;
}

button.btn.btn-light.light-grey {
  color: #6c757d;
}
button.presence-sheet.live {
  height: 38px;
  font-weight: 700;
  font-size: 14px;
  margin-right: 14px;
}

button.presence-sheet.live:active,
button.presence-sheet.live:focus {
  background-color: #dee2e6;
  border-color: #dee2e6;
}

div.modal-header.assembly-live-timesheet-modal {
  h5 {
    width: 100%;
    div button.presence-sheet {
      margin-right: -25px;
    }
  }
}

.row-cs-form-fallback {
  min-height: 90px;
}

div.modal-header.title-bold-no-footer {
  h5 {
    color: #36383a;
    font-size: 18px;
    font-weight: 700;
  }
}

div.modal-footer.footer-title-bold-no-footer {
  border-top: none;
}

div.card.shadow {
  height: 590px;
  min-width: 665px;
  overflow: auto;
  z-index: 1000;
  position: fixed;
  right: 0px;
  &.live {
    top: 74px;
  }
  &.ag-detail {
    top: 105px;
  }
  &.full-page-layout {
    top: 146px;
  }

  @media screen and (max-height: 720px) {
    height: 550px !important;
  }
  @media screen and (max-height: 680px) {
    height: 500px !important;
  }
  @media screen and (max-height: 640px) {
    height: 450px !important;
  }
}

div.modal-footer.footer-no-footer {
  border-top: none;
}

div.modal-backdrop {
  &.simple-modal {
    z-index: 10000;
  }
  background-color: rgba(0, 0, 0, 0.5);
}
/*---------------
TABLEVIEW
------------------*/
td.clickable {
  &.ag-today-3,
  &.ag-future-3,
  &.ag-anomaly-3,
  &.ag-closed-3 {
    width: 100%;
  }
  &.ag-closed-4 {
    min-width: 158px;
    text-align: right;
  }
  &.ag-closed-5 {
    min-width: 128px;
  }
  &.ag-future-1,
  &.ag-future-2 {
    white-space: nowrap;
  }

  &.ag-today-1,
  &.ag-future-1 {
    min-width: 124px;
  }
  &.ag-today-2,
  &.ag-future-2 {
    min-width: 80px;
  }
  &.ag-today-6,
  &.ag-future-6,
  &.ag-anomaly-5 {
    min-width: 145px;
  }
  &.ag-today-7,
  &.ag-future-7 {
    min-width: 145px;
  }
}

table.ag-listing,
table.ag-anomaly,
table.ag-future,
table.ag-closed {
  > :not(caption) > * > * {
    padding: 12px 16px !important;
  }
}

table.ag-future,
table.ag-anomaly,
table.ag-closed {
  margin-bottom: 0px;
}

.border-orange {
  border-color: #eb8500 !important;
}

.border-green {
  border-color: #54b96b !important;
}

/*---------------
GENERAL
------------------*/
.font-weight-semi-bold {
  font-weight: 600;
}
