.tile:global(.react-calendar__tile--now),
.tile:global(.react-calendar__tile--now:hover),
.tile:global(.react-calendar__tile--now:focus) {
  background-color: var(--bs-light);
  border-radius: 6px;
}

.tile:global(.react-calendar__tile),
.tile:global(.react-calendar__tile:hover),
.tile:global(.react-calendar__tile:focus) {
  position: relative;
  border: 2px solid white;
  border-radius: 6px;
}

.tile:global(.react-calendar__tile--active),
.tile:global(.react-calendar__tile--active:hover),
.tile:global(.react-calendar__tile--active:focus) {
  background-color: var(--bs-danger);
  color: var(--bs-white) !important;
  border: 2px solid white;
  border-radius: 6px;
}

.tile:global(.react-calendar__tile--hasActive),
.tile:global(.react-calendar__tile--hasActive:hover),
.tile:global(.react-calendar__tile--hasActive:focus) {
  background-color: var(--bs-danger);
  color: var(--bs-white);
  border: 2px solid white;
  border-radius: 6px;
}

.redDot:after {
  content: '•';
  position: relative;
  display: block;
  height: 0;
  color: red;
}

:global(.react-calendar) {
  border: none;
  border-radius: 0.25rem;
}

.tile:global(.react-calendar__month-view__days__day--weekend) {
  color: black;
}
