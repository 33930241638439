.button {
  composes: btn disabled from global;
  pointer-events: all !important;
  position: relative;
  padding-left: 30px;
  border: 2px solid var(--bs-primary);
  overflow: hidden;
}

.progress {
  composes: btn from global;
  color: inherit;
  border-radius: 0;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 42px;
  transition: width 0.5s ease-in;
}

.progress:hover {
  color: inherit;
}

.visible {
  width: 100%;
  opacity: 1 !important;
}

.progress > svg {
  color: inherit !important;
}

.button:hover > .progress {
  width: 100%;
  transition: width 1.2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.button:hover {
  opacity: 1;
  transition: all 1.2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.text {
  position: relative;
  z-index: 1;
}
