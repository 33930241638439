.loader {
  display: block;
  position: relative;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--bs-dark);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--bs-dark);
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.loader:after {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--bs-primary);
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.loaderSm {
  display: block;
  position: relative;
  width: 75px;
  height: 75px;
  margin: 0;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--bs-dark);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loaderSm:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--bs-dark);
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.loaderSm:after {
  content: '';
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--bs-primary);
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.loaderXs {
  display: block;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: var(--bs-dark);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loaderXs:before {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: var(--bs-dark);
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.loaderXs:after {
  content: '';
  position: absolute;
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: var(--bs-primary);
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.loaderXXs {
  display: block;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: var(--bs-dark);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loaderXXs:before {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: var(--bs-dark);
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.loaderXXs:after {
  content: '';
  position: absolute;
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: var(--bs-primary);
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
