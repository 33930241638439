@import './../../styles/logos.variables.css';

.background {
  flex-grow: 1;
  background-attachment: fixed;
  background-position: center 0px;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.box {
  composes: me-2 me-sm-0 ms-2 ms-sm-0 border-0 w-100 from global;
  box-shadow: 0px 4px 20px 0 rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 1rem;
  overflow: hidden;
  height: 100%;
}

.boxLeft {
  composes: col-md-4 text-white from global;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.6);

  @media (min-width: 768px) {
    padding: 3rem;
  }
}

.boxRight {
  composes: col-md-8 from global;
  background: rgba(255, 255, 255, 1);
  padding: 2rem 1rem;
}
@media (min-width: 768px) {
  .boxRight {
    padding: 6rem;
  }
  .boxLeft {
    padding: 3rem;
  }
}

.signLogo {
  composes: mt-4 mb-md-4 from global;
  background-image: var(--sign-logo-url);
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  height: 38px;
}
